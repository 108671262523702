.message{
    padding: 10px;
    /* background: #fafafa; */
    background: #ffffff;
    color: #333;
    box-shadow: inset 0 0 0 1px rgba(0,0,0,0.1);
    font-size: 16px;
    width:100%;
    text-align: center;
    font-family: 'Ubuntu', sans-serif;
}
.selector {
    box-shadow: none !important;
    outline: none !important;
    margin-bottom: 20px;
    width: 100%;
    padding: 8px 10px 10px 56px;
    border-radius: 4px;
    border: 1px solid #c8c8c8;
    position: relative;
    color: var(--darkslate);
}

.login-form .selector::placeholder{
	color: var(--darkslate);
}